import React, { useState, useContext, useEffect, useRef } from 'react'
import AppSlideOver from './AppSlideOver'
import { XMarkIcon } from '@heroicons/react/20/solid'
import calenderIcon from '../assets/Icons/calenderIcon.svg'
import attachmentIcon from '../assets/Icons/attachmentIcon.svg'
import DatePicker from 'react-datepicker'
import { DropZone, FileObj } from '../utils/common/FileDrop'
import moment from 'moment'
import { LookUpContext } from '../pages/dashboard/Layout'
import { IReport } from '../pages/dashboard/reports/IndividualCourse'
import { useForm, Controller } from 'react-hook-form'
import ScreenLoader from '../utils/screenLoader'
import { submitReportForm } from '../api/IndividualReportSlide'
import axios from '../utils/axios'
import { getFileUrl, uploadFile } from '../utils/fileAction'
import AuthorImg from './AuthorImg'

export interface UpdateReport {
	clientid: string
	courseid: string
	learnerid: string
	courseProgress: string
	MathFS: string
	EnglishFS: string
	Rag: string
	Percentage: string
	FunctionalSkills: string
	withdrawn: string
	nextDate: any
	Flag: string
	Comment: string
	Files: number[]
	completionDate: any
	completionStatus: string
	StartDate: any
	PlannedEndDate: any
	EndDate: any
	bilStartDate: any
	bilEndDate: any
	epaStartDate: any
	LeaverDate: any
	AdditionalTag: string
}
export interface IUdate extends IReport {
	completionDate: any
	withdrawn: string
	completionStatus: string
	bilStartDate: any
	bilEndDate: any
	StartDate: any
	PlannedEndDate: any
	EndDate: any
	epaStartDate: any
	LeaverDate: any
	AdditionalTag: string
}

export default function IndividualReport(props: any) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		reset,
		setError,
		trigger,
		clearErrors,
		control,
		setValue
	} = useForm()

	const formRef = useRef<any>(null)

	useEffect(() => {
		setIsRagError(individualReport?.ragRating)
	}, [errors])

	let lookUps = useContext(LookUpContext)

	const withdrawnReasons = [
		'Alternative internal offer',
		'BTS (Back to start)',
		'Course cancelled',
		'Cannot commit to completion',
		'Illness/Injury',
		'Lack of business support',
		'Programme not as expected',
		'Redundant',
		'Resigned',
		'Transfer',
		'Reason not known',
		'Other'
	]

	const [englishOPtions, setEnglishOPtions] = useState([
		'Exempt',
		'Ongoing',
		'Passed',
		'Failed'
	])
	const [withdrawnOPtions, setWithdrawnOPtions] = useState(
		props?.selectedReport?.withdrawnReasons ?? 'Reason not known'
	)

	const [individualReport, setIndividualReport] = useState(
		{ ...props.selectedReport } ?? {}
	)
	const originalcourseProgress = props?.selectedReport?.courseProgress
	const bilToOngoing =
		props?.selectedReport?.courseProgress === 'Break in learning' &&
		individualReport?.courseProgress === 'Ongoing'

	const [math, setMath] = useState(
		props?.selectedReport?.fsmathStatus
			? props?.selectedReport?.fsmathStatus
			: englishOPtions[0]
	)
	const [english, setEnglish] = useState(
		props?.selectedReport?.fsenglishStatus
			? props?.selectedReport?.fsenglishStatus
			: englishOPtions[0]
	)

	const [updatePhoto, setUpdatePhoto] = useState('')

	const [showRevisedEndDateInput, setShowRevisedEndDateInput] = useState(false)

	useEffect(() => {
		if (props.selectedReport) {
			// axios.post(`/api/report/detail?enrollmentId=${props.selectedReport.enrollmentId}&refId=${props.selectedReport.refId}`).then(({data}) => {
			//   const res = data.data
			setIndividualReport({ ...props.selectedReport })
			reset(props.selectedReport)
			// })
			if (props.selectedReport.lastModifyPhoto) {
				getFileUrl(props.selectedReport.lastModifyPhoto).then((url) =>
					setUpdatePhoto(url)
				)
			}
			setLstFiles(props.selectedReport.detailFiles || [])
		}
	}, [props.selectedReport])

	// useEffect(() => {
	//   let filesList: any[] = [...(props.fileArray as any)];
	//   setLstFiles(filesList);
	// }, [props.fileArray || props.fileArray?.length]);

	useEffect(() => {
		if (
			individualReport?.bilEndDate &&
			moment(individualReport?.bilEndDate).isBefore(moment())
		) {
			changeSelection('courseProgress', 'Ongoing')
		}
	}, [
		individualReport?.bilEndDate &&
			individualReport.courseProgress &&
			individualReport.courseProgress.toLowerCase() === 'break in learning'
	])

	useEffect(() => {
		const getValue = () => {
			// End date value is null
			if (bilToOngoing && !individualReport?.bilEndDate) {
				return new Date()
			}
			// // End date value is present but the value is in future
			if (bilToOngoing && individualReport?.bilEndDate) {
				if (moment(individualReport?.bilEndDate).isAfter(moment())) {
					return new Date()
				} else {
					return individualReport?.bilEndDate
				}
			}
			return props?.selectedReport?.bilEndDate
		}
		let value = getValue()
		changeSelection('bilEndDate', value)
		setValue('bilEndDate', value)

		if (
			['Failed'].indexOf(individualReport?.courseProgress) !== -1 ||
			['Completed'].indexOf(individualReport?.courseProgress) !== -1
		) {
			if (!individualReport?.completionDate) {
				changeSelection('completionDate', new Date())
				setValue('completionDate', new Date())
			} else {
				changeSelection('completionDate', individualReport?.completionDate)
				setValue('completionDate', individualReport?.completionDate)
			}
		} else if (
			['Failed'].indexOf(individualReport?.courseProgress) === -1 ||
			['Completed'].indexOf(individualReport?.courseProgress) === -1
		) {
			changeSelection('completionDate', null)
			setValue('completionDate', null)
		}
	}, [individualReport?.courseProgress])

	const [startDate, setStartDate] = useState<any>()
	const [lstFiles, setLstFiles] = useState<any[]>([])
	const [fileKeys, setFileKeys] = useState<any>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [isFormTouched, setIsFormTouched] = useState<boolean>(false)
	const [isRagError, setIsRagError] = useState<boolean>(false)
	const [show, setShow] = useState<boolean>(false)

	const bytesToSize = (bytes: number) => {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
		if (bytes === 0) return 'n/a'
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
		if (i === 0) return bytes + ' ' + sizes[i]
		return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
	}
	useEffect(() => {
		setStartDate(
			props.selectedReport?.nextDate
				? new Date(props.selectedReport?.nextDate)
				: null
		)
	}, [props.selectedReport?.nextDate])

	const ragRatingClass =
		individualReport?.ragRating === 'Red'
			? 'red'
			: individualReport?.ragRating === 'Amber'
			? 'yellow'
			: individualReport?.ragRating === 'Green'
			? 'green'
			: 'gray'
	const ragRatingRender = (rag: any) => {
		return (
			<Controller
				defaultValue={rag}
				control={control}
				{...register('ragRating', {
					required: true
				})}
				render={({ field: { onChange } }) => (
					<select
						id=""
						className={
							errors.ragRating
								? `ragSelect focus:border-red-500 relative block w-full rounded-md font-poppins-400 sm:text-sm text-red border bg-red border-1 border-red-500`
								: `ragSelect focus:border-current-${ragRatingClass} relative block w-full rounded-md font-poppins-400 sm:text-sm text-${ragRatingClass}-800 border bg-${ragRatingClass}-100 border-1 border-${ragRatingClass}-rag`
						}
						value={individualReport?.ragRating}
						onChange={(e) => {
							changeSelection('ragRating', e.target.value)
							onChange(e.target.value)
						}}
					>
						{!individualReport?.ragRating && (
							<option value="" className={'text-gray-700'}>
								Please select RAG status
							</option>
						)}
						<option value="Red" className="redSelect">
							Red
						</option>
						<option value="Amber" className="yellowSelect">
							Amber
						</option>
						<option value="Green" className="greenSelect">
							Green
						</option>
					</select>
				)}
			></Controller>
		)
	}
	const _commentRender = (comment: any) => {
		return (
			<Controller
				defaultValue={comment}
				control={control}
				{...register('comment', {
					required:
						individualReport?.courseProgress?.toLowerCase() !== 'not started' &&
						individualReport?.courseProgress &&
						(individualReport?.ragRating?.toLowerCase() === 'amber' ||
							individualReport?.ragRating?.toLowerCase() === 'red')
				})}
				render={({ field: { onChange } }) => (
					<textarea
						id=""
						name=""
						value={individualReport?.comment}
						onChange={(e: any) => {
							changeSelection('comment', e.target.value)
							onChange(e.target.value)
						}}
						className={
							errors.comment
								? 'h-24 w-full mr-2 rounded  border-red-500 bg-white text-gray focus:outline-none text-sm'
								: 'h-24 w-full mr-2 rounded  border-gray-300 bg-white text-gray focus:outline-none text-sm'
						}
					></textarea>
				)}
			></Controller>
		)
	}

	const isBefore = (date1: Date, date2: Date) => {
		return date1 && date2
			? moment(date1).isBefore(
					moment(new Date(date2).setDate(new Date(date2).getDate() + 1))
			  )
			: true
	}

	const isDateBefore = (date1: Date, date2: Date) => {
		return date1 && date2
			? moment(new Date(date1).setDate(new Date(date1).getDate())).isBefore(
					moment(new Date(date2).setDate(new Date(date2).getDate() + 1))
			  )
			: true
	}

	const isValidDate = (date: Date) => {
		const regexValidator =
			/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/
		if (date === null) {
			return true
		}
		const formattedDate = moment(date).format('DD/MM/yyyy')
		return regexValidator.test(formattedDate)
	}

	const changeSelection = (prop: any, value: any) => {
		setIsFormTouched(true)
		let obj: any = individualReport
		obj[prop as keyof typeof individualReport] = value
		setIndividualReport({ ...obj })
	}

	const saveReport = async () => {
		setLoading(true)
		const data = {
			individualReport,
			startDate,
			lookUps,
			math,
			english,
			withdrawnOPtions,
			lstFiles,
			fileKeys
		}
		let res = await submitReportForm(data)
		if (res.status === 200) {
			props.toggle(false, true)
			props.setMessage('Report updated successfully.')
			props.setName(data.individualReport?.LearnerName)
			setShow(true)
			props.setFileArray([])
			clearErrors()
		} else {
			setShow(false)
			props.setShowErrorToaster('Update failed.')
		}
		setLoading(false)
	}

	const bilReturnDateLabel = `BIL Return Date ${
		originalcourseProgress === 'Break in learning' &&
		individualReport?.courseProgress === 'Ongoing'
			? ' *'
			: ''
	}`

	const submitReport = () => {
		if (individualReport?.ragRating) {
			saveReport()
		} else {
			if (
				individualReport?.courseProgress?.toLowerCase() !== 'not started' &&
				individualReport?.courseProgress
			) {
				setIsRagError(true)
			} else {
				saveReport()
			}
		}
	}
	const sortedArray = [
		'Ongoing',
		'Break in learning',
		'In EPA',
		'Completed',
		'Failed',
		'Withdrawn'
	]

	const customSort = (a: any, b: any) => {
		return sortedArray.indexOf(a) - sortedArray.indexOf(b)
	}

	const checkForFocus = () => {
		if (errors) {
			if (formRef.current !== null) {
				formRef.current.focus()
			}
		}
	}

	const handle4DownloadFile = (key: string) => {
		getFileUrl(key).then((url) => window.open(url))
	}

	return (
		<>
			<AppSlideOver toggle={props.toggle} show={props.show}>
				{
					<div className="flex min-h-0 flex-1 flex-col pt-6 slide-over-input">
						<div className=" px-4 sm:px-6">
							<div className="flex items-start justify-between">
								<div className="text-lg font-inter-500 leading-7 text-gray-900">
									{`${individualReport?.firstName} ${individualReport?.lastName}`}
								</div>
								<div className="ml-3 flex h-7 items-center">
									<button
										type="button"
										className=" text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => {
											props.toggle(false)
										}}
									>
										<span className="sr-only">Close panel</span>
										<XMarkIcon className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
							</div>
							<div className="sm:px-1 text-sm text-gray-500">
								{individualReport?.uln}
							</div>
						</div>

						<div className=" relative overflow-y-auto mt-6 pb-0 flex-1">
							<form
								className="mb-5"
								id="personal_detail"
								onSubmit={handleSubmit(submitReport)}
							>
								<div className="border-t border-gray-200">
									<dl className="divide-y divide-gray-200">
										<div className="py-2 flex items-center justify-between px-4 sm:px-6">
											<dt className="text-sm font-inter-500 text-gray-500">
												Updated by
											</dt>
											<dd className="text-sm font-inter-500 text-gray-900 flex items-center">
												{individualReport?.lastModifyName ||
												individualReport?.lastModifyPhoto ? (
													<>
														<div className="m-1">
															<AuthorImg
																size="mini"
																name={individualReport?.lastModifyName}
																url={individualReport?.lastModifyPhoto}
															></AuthorImg>
														</div>
														{individualReport?.lastModifyName}
													</>
												) : (
													<></>
												)}
											</dd>
										</div>
										<div className="py-2 flex items-center justify-between px-4 sm:px-6">
											<dt className="text-sm font-inter-500 text-gray-500">
												Last updated on
											</dt>
											<dd className="text-sm font-inter-500 text-gray-900 flex items-center">
												{individualReport?.lastModifiedDate
													? moment(
															new Date(individualReport?.lastModifiedDate)
													  ).format('DD/MM/yyyy')
													: ''}
											</dd>
										</div>
									</dl>
								</div>
								{!individualReport?.hasStartDateAndPlannedEndDate && (
									<div className="flex-1">
										<div className="pt-6 pb-2 border-t border-b border-gray-200 px-4 sm:px-6">
											<div className="font-poppins-500 text-gray-900 text-md">
												Course dates
											</div>
										</div>
										<div className="flex pt-4">
											<div className="py-4 pr-1 sm:pl-6 w-[386px]">
												<label
													htmlFor="fileName"
													className={
														errors.startDate?.type
															? 'block text-sm font-poppins-300 text-red-500'
															: 'block text-sm font-poppins-300 text-gray-600'
													}
												>
													Start date
												</label>
												<div className="relative mt-1">
													<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-index">
														<img src={calenderIcon} alt=""></img>
													</div>
													<Controller
														defaultValue={new Date(individualReport?.startDate)}
														control={control}
														render={({ field: { onChange } }) => (
															<DatePicker
																dateFormat="dd/MM/yyyy"
																placeholderText={'Select start date...'}
																className={
																	errors.StartDate?.type
																		? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																		: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																}
																onChange={(value: Date) => {
																	changeSelection('startDate', value)
																	onChange(value)
																}}
																selected={
																	individualReport?.startDate
																		? new Date(individualReport?.startDate)
																		: ''
																}
															/>
														)}
														{...register('startDate', {
															required: false,
															validate: () =>
																individualReport?.courseProgress !==
																'Not Started'
																	? isValidDate(individualReport?.StartDate)
																	: true
														})}
													/>
												</div>
												<p
													className="mt-2 text-xs text-red-500"
													id="email-error"
												>
													<>
														{errors.startDate?.type === 'required'
															? 'Start date is required'
															: errors.startDate?.type === 'validate'
															? 'Valid Format DD/MM/YYYY'
															: ''}
													</>
												</p>
											</div>
											<div className="py-4 pt-12 text-gray-500">-</div>
											<div className="py-4 pl-1 sm:pr-6 w-full">
												<label
													htmlFor="fileName"
													className={
														errors.plannedEndDate?.type
															? 'block text-sm font-poppins-300 text-red-500'
															: 'block text-sm font-poppins-300 text-gray-600'
													}
												>
													Planned end date
												</label>

												<div className="relative mt-1">
													<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3   z-index">
														<img src={calenderIcon} alt=""></img>
													</div>
													<Controller
														defaultValue={individualReport?.plannedEndDate}
														control={control}
														render={({ field: { onChange } }) => (
															<DatePicker
																dateFormat="dd/MM/yyyy"
																placeholderText={'Select end date...'}
																className={
																	errors.plannedEndDate?.type
																		? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																		: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																}
																onChange={(value: Date) => {
																	changeSelection('plannedEndDate', value)
																	onChange(value)
																}}
																selected={
																	individualReport?.plannedEndDate
																		? new Date(individualReport?.plannedEndDate)
																		: ''
																}
															/>
														)}
														{...register('plannedEndDate', {
															required: false,
															validate: () =>
																isValidDate(individualReport?.plannedEndDate)
														})}
													/>
												</div>
												<p
													className="mt-2 text-xs text-red-500"
													id="email-error"
												>
													<>
														{errors.plannedEndDate?.type === 'required'
															? 'Planned end date is required'
															: errors.plannedEndDate?.type === 'validate'
															? 'Valid Format DD/MM/YYYY'
															: ''}
													</>
												</p>
											</div>
										</div>
									</div>
								)}

								{/* Under Report Update Section */}
								<div className="flex-1">
									<div className="pt-6 pb-2 border-t border-b border-gray-200 px-4 sm:px-6">
										<div className="font-poppins-500 text-gray-900 text-md">
											Report Update
										</div>
									</div>

									<div className="py-4 border-b border-gray-200 px-4 sm:px-6">
										<div>
											<label
												htmlFor="Title"
												className="block text-sm font-poppins-400 text-gray-700"
											>
												Course status
											</label>

											<div className="mt-1 relative">
												<select
													ref={formRef}
													value={individualReport?.courseProgress}
													id=""
													name=""
													onChange={(e) => {
														changeSelection('courseProgress', e.target.value)
													}}
													className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
												>
													{(individualReport?.courseProgress ===
														'Not Started' ||
														!individualReport?.courseProgress) && (
														<option value="disabled selected">
															Not Started
														</option>
													)}
													{sortedArray.map((courseProgress: any) => (
														<option key={courseProgress} value={courseProgress}>
															{courseProgress}
														</option>
													))}
												</select>
											</div>
										</div>

										{/* Conditional rendering fields */}
										{individualReport?.courseProgress === 'Failed' && (
											<div className="pt-4">
												<label
													htmlFor="fileName"
													className={
														errors.completionDate
															? 'block text-sm font-poppins-400 text-red-500'
															: 'block text-sm font-poppins-400 text-gray-600'
													}
												>
													Date training ended *
												</label>

												<div className="relative mt-1">
													<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3   z-index">
														<img src={calenderIcon} alt=""></img>
													</div>

													<Controller
														defaultValue={
															individualReport?.completionDate ?? new Date()
														}
														control={control}
														render={({ field: { onChange } }) => (
															<DatePicker
																dateFormat="dd/MM/yyyy"
																placeholderText={'Select date...'}
																className={
																	errors.completionDate
																		? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-red-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																		: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																}
																onChange={(value: Date) => {
																	changeSelection('completionDate', value)
																	onChange(value)
																}}
																selected={
																	individualReport?.completionDate
																		? new Date(individualReport?.completionDate)
																		: ''
																}
															/>
														)}
														{...register('completionDate', {
															required: true
														})}
													/>
												</div>
												<p
													className="mt-2 text-sm text-red-500"
													id="email-error"
												>
													<>{errors.completionDate ? 'Date is required' : ''}</>
												</p>
											</div>
										)}

										{/* Complete Course start */}
										{individualReport?.courseProgress === 'Completed' && (
											<>
												<div className="pt-4">
													<label
														htmlFor="fileName"
														className={
															errors.completionDate
																? 'block text-sm font-poppins-400 text-red-500'
																: 'block text-sm font-poppins-400 text-gray-600'
														}
													>
														Completion date *
													</label>

													<div className="relative mt-1">
														<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3   z-index">
															<img src={calenderIcon} alt=""></img>
														</div>
														<Controller
															defaultValue={
																individualReport?.completionDate ?? new Date()
															}
															control={control}
															render={({ field: { onChange } }) => (
																<DatePicker
																	dateFormat="dd/MM/yyyy"
																	placeholderText={'Select completion date...'}
																	className={
																		errors.completionDate
																			? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-red-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																			: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																	}
																	onChange={(value: Date) => {
																		changeSelection('completionDate', value)
																		onChange(value)
																	}}
																	selected={
																		individualReport?.completionDate
																			? new Date(
																					individualReport?.completionDate
																			  )
																			: ''
																	}
																/>
															)}
															{...register('completionDate', {
																required: true
															})}
														/>
													</div>
													<p
														className="mt-2 text-sm text-red-500"
														id="email-error"
													>
														<>
															{errors.completionDate
																? 'Completion Date is required'
																: ''}
														</>
													</p>
												</div>

												<div className="pt-4">
													<label
														htmlFor="fileName"
														className="block text-sm font-poppins-400 text-gray-700"
													>
														Completion status
													</label>

													<div className="relative mt-1">
														<select
															id=""
															name=""
															className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
															value={individualReport?.completionStatus ?? ''}
															onChange={(e) => {
																changeSelection(
																	'completionStatus',
																	e.target.value ?? ''
																)
															}}
														>
															<option value="" disabled selected hidden>
																Select
															</option>
															{['Pass', 'Merit', 'Distinction'].map(
																(status: any) => (
																	<option key={status} value={status}>
																		{status}
																	</option>
																)
															)}
														</select>
													</div>
												</div>
											</>
										)}

										{/* Break in learning Course status */}
										{((originalcourseProgress === 'Break in learning' &&
											individualReport?.courseProgress === 'Ongoing') ||
											individualReport?.courseProgress ===
												'Break in learning') && (
											<div className="flex pt-4">
												<div>
													<label
														htmlFor="fileName"
														className={
															errors.bilStartDate?.type
																? 'block text-sm font-poppins-400 text-red-500'
																: 'block text-sm font-poppins-400 text-gray-600'
														}
													>
														BIL start date *
													</label>

													<div className="relative mt-1">
														<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-index">
															<img src={calenderIcon} alt=""></img>
														</div>
														<Controller
															defaultValue={
																individualReport?.bilStartDate ?? new Date()
															}
															control={control}
															render={({ field: { onChange } }) => (
																<DatePicker
																	dateFormat="dd/MM/yyyy"
																	// placeholderText={"[current Date]"}
																	placeholderText={'Select start date...'}
																	className={
																		errors.bilStartDate?.type
																			? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																			: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																	}
																	onChange={(value: Date) => {
																		changeSelection('bilStartDate', value)
																		onChange(value)
																	}}
																	selected={
																		individualReport?.bilStartDate
																			? new Date(individualReport?.bilStartDate)
																			: ''
																	}
																/>
															)}
															{...register('bilStartDate', {
																required: true,
																validate: () =>
																	isDateBefore(
																		individualReport?.bilStartDate,
																		individualReport?.bilEndDate
																	)
															})}
														/>
													</div>
													<p
														className="mt-2 text-sm text-red-500"
														id="email-error"
													>
														<>
															{errors.bilStartDate?.type === 'required'
																? 'Date is required'
																: errors.bilStartDate?.type === 'validate'
																? 'BIL start date should before return date'
																: ''}
														</>
													</p>
												</div>
												<div className="px-3 pt-8 text-gray-500">-</div>
												<div>
													<label
														htmlFor="fileName"
														className={
															errors.bilEndDate
																? 'block text-sm font-poppins-400 text-red-500'
																: 'block text-sm font-poppins-400 text-gray-600'
														}
													>
														{bilReturnDateLabel}
													</label>

													<div className="relative mt-1">
														<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3   z-index">
															<img src={calenderIcon} alt=""></img>
														</div>
														<Controller
															defaultValue={individualReport?.bilEndDate ?? ''}
															control={control}
															render={({ field: { onChange } }) => (
																<DatePicker
																	dateFormat="dd/MM/yyyy"
																	placeholderText={'Select return date...'}
																	className={
																		errors.bilEndDate
																			? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																			: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																	}
																	onChange={(value: Date) => {
																		changeSelection('bilEndDate', value)
																		onChange(value)
																	}}
																	selected={
																		individualReport?.bilEndDate
																			? new Date(individualReport?.bilEndDate)
																			: ''
																	}
																/>
															)}
															{...register('bilEndDate', {
																required: bilToOngoing
															})}
														/>
													</div>
													<p
														className="mt-2 text-sm text-red-500"
														id="email-error"
													>
														{errors.bilEndDate?.type === 'required'
															? 'Date is required'
															: ''}
													</p>
												</div>
											</div>
										)}

										{/* Break in learning Course status */}
										{individualReport?.courseProgress === 'In EPA' && (
											<>
												<div className="pt-4">
													<label
														htmlFor="fileName"
														className={
															errors.epaStartDate
																? 'block text-sm font-poppins-400 text-red-500'
																: 'block text-sm font-poppins-400 text-gray-700'
														}
													>
														EPA start date *
													</label>

													<div className="relative mt-1">
														<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3   z-index">
															<img src={calenderIcon} alt=""></img>
														</div>
														<Controller
															defaultValue={
																individualReport?.epaStartDate ?? new Date()
															}
															control={control}
															render={({ field: { onChange } }) => (
																<DatePicker
																	dateFormat="dd/MM/yyyy"
																	// placeholderText={"[current Date]"}
																	placeholderText={'Select start date...'}
																	className={
																		errors.epaStartDate
																			? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																			: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																	}
																	onChange={(value: Date) => {
																		changeSelection('epaStartDate', value)
																		onChange(value)
																	}}
																	selected={
																		individualReport?.epaStartDate
																			? new Date(individualReport?.epaStartDate)
																			: ''
																	}
																/>
															)}
															{...register('epaStartDate', {
																required: true
															})}
														/>
													</div>
													<p
														className="mt-2 text-sm text-red-500"
														id="email-error"
													>
														<>
															{errors.epaStartDate
																? 'EPA start date is required'
																: ''}
														</>
													</p>
												</div>
											</>
										)}

										{individualReport?.courseProgress === 'Withdrawn' && (
											<>
												<div className="pt-4">
													<label
														htmlFor="fileName"
														className={
															errors.completionDate
																? 'block text-sm font-poppins-400 text-red-500'
																: 'block text-sm font-poppins-400 text-gray-700'
														}
													>
														Withdrawn date *
													</label>

													<div className="relative mt-1">
														<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3   z-index">
															<img src={calenderIcon} alt=""></img>
														</div>

														<Controller
															defaultValue={
																individualReport?.completionDate ?? new Date()
															}
															control={control}
															render={({ field: { onChange } }) => (
																<DatePicker
																	dateFormat="dd/MM/yyyy"
																	//  placeholderText={"[current Date]"}
																	placeholderText={'Select date withdrawn...'}
																	className={
																		errors.completionDate
																			? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-red-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-red-700 focus:shadow-sm sm:text-sm'
																			: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																	}
																	onChange={(value: Date) => {
																		changeSelection('completionDate', value)
																		onChange(value)
																	}}
																	selected={
																		individualReport?.completionDate
																			? new Date(
																					individualReport?.completionDate
																			  )
																			: ''
																	}
																/>
															)}
															{...register('completionDate', {
																required: true
															})}
														/>
													</div>
													<p
														className="mt-2 text-sm text-red-500"
														id="email-error"
													>
														<>
															{errors.completionDate
																? 'Withdrawn Date is required'
																: ''}
														</>
													</p>
												</div>
												<div className="pt-4">
													<label
														htmlFor="fileName"
														className="block text-sm font-poppins-400 text-gray-700"
													>
														Withdrawn reasons
													</label>

													<div className="relative mt-1">
														<select
															id=""
															name=""
															className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
															value={withdrawnOPtions}
															onChange={(e) => {
																setWithdrawnOPtions(e.target.value)
															}}
														>
															<option value="" disabled selected hidden>
																{/* [Select] */}
															</option>
															{withdrawnReasons.map((status: any) => (
																<option key={status} value={status}>
																	{status}
																</option>
															))}
														</select>
													</div>
												</div>
											</>
										)}
									</div>
								</div>

								{/* Sections RAG, Percentage completion, Enligh FS, Math FS and Next Review */}
								{individualReport?.courseProgress?.toLowerCase() !==
									'not started' &&
									individualReport?.courseProgress && (
										<>
											<div className="flex-1">
												<div className="py-4 border-b border-gray-200 px-4 sm:px-6">
													<label
														htmlFor="Rag"
														className={
															errors.ragRating
																? 'block text-sm font-poppins-400 text-red-500'
																: 'block text-sm font-poppins-400 text-gray-700'
														}
													>
														RAG *
													</label>
													<div
														className={
															errors.ragRating
																? 'mt-1 relative flex border-red-700 shadow-sm font-poppins-400 focus:outline-none active:border-red-700 focus:border-red-700 justify-between items-center space-x-4 text-center font-medium text-sm'
																: 'mt-1 relative flex text-red-700 active:border-red-700 focus:border-red-700 justify-between items-center space-x-4 text-center font-medium text-sm'
														}
													>
														{ragRatingRender(individualReport?.ragRating)}
													</div>
													<p
														className="mt-2 text-sm text-red-500"
														id="email-error"
													>
														<>
															{errors.ragRating ? 'RAG status is required' : ''}
														</>
													</p>
												</div>
											</div>
											<div className="flex-1">
												<div className="py-4 border-b border-gray-200 px-4 sm:px-6">
													<label
														htmlFor="fileName"
														className={
															errors.percentage
																? 'block text-sm font-poppins-400 text-red-500'
																: 'block text-sm font-poppins-400 text-gray-700'
														}
													>
														Percentage of completion (%)
													</label>
													<div className="mt-1">
														<Controller
															control={control}
															{...register('percentage', {
																required: false,
																validate: (value) => {
																	const floatValue = parseFloat(
																		`${value}`.replace('%', '')
																	)
																	if (isNaN(floatValue)) {
																		return true // Skip validation if not a number
																	}
																	const regex = /^\d+(\.\d{1,2})?%?$/
																	return (
																		regex.test(value) &&
																		floatValue >= 0 &&
																		floatValue <= 100
																	)
																}
															})}
															render={({ field: { onChange } }) => (
																<input
																	// value={individualReport?.Percentage}
																	value={
																		individualReport?.percentage
																			? `${parseFloat(
																					individualReport?.percentage
																			  )}`
																			: ''
																	}
																	placeholder="0"
																	type="text"
																	id="percentage"
																	className={
																		errors.percentage?.type === 'validate'
																			? 'block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																			: 'block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																	}
																	onChange={(e: any) => {
																		const inputValue = e.target.value
																		const floatValue = parseFloat(
																			inputValue.replace('%', '')
																		) // Remove '%' and parse as float
																		changeSelection('percentage', floatValue)
																		// changeSelection("Percentage", e.target.value);
																		onChange(floatValue)
																	}}
																/>
															)}
														></Controller>
													</div>
													{errors.percentage && (
														<p
															className="mt-2 text-sm text-red-500"
															id="email-error"
														>
															<>
																{errors.percentage?.type === 'validate'
																	? 'Please enter valid numeric % value 1 to 100'
																	: 'Please enter a valid % value 1 and 100'}
															</>
														</p>
													)}
												</div>
											</div>
											<div className="flex-1">
												<div className="flex-1">
													<div className="flex-1">
														<div className="w-full relative flex border-b border-gray-200">
															<div className="py-4 pr-1 sm:pl-6 w-full">
																<label
																	htmlFor="Title"
																	className="block text-sm font-poppins-400 text-gray-700"
																>
																	{/* Functional skills */}
																	English FS *
																</label>

																<div className="mt-1 relative">
																	<select
																		id=""
																		name=""
																		className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
																		value={english || ''}
																		onChange={(e) => {
																			setEnglish(e.target.value)
																		}}
																	>
																		<option value="" disabled selected hidden>
																			Select
																		</option>
																		{englishOPtions.map(
																			(functionalSkill: any) => (
																				<option
																					key={functionalSkill}
																					value={functionalSkill}
																				>
																					{functionalSkill}
																				</option>
																			)
																		)}
																	</select>
																</div>
															</div>
															<div className="py-4 pl-1 sm:pr-6 w-full">
																<label
																	htmlFor="Title"
																	className="block text-sm font-poppins-400 text-gray-700"
																>
																	{/* Functional skills */}
																	Math FS *
																</label>

																<div className="mt-1 relative">
																	<select
																		id=""
																		name=""
																		className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
																		value={math || ''}
																		onChange={(e) => {
																			setMath(e.target.value)
																		}}
																	>
																		<option value="" disabled selected hidden>
																			Select
																		</option>
																		{englishOPtions.map(
																			(functionalSkill: any) => (
																				<option
																					key={functionalSkill}
																					value={functionalSkill}
																				>
																					{functionalSkill}
																				</option>
																			)
																		)}
																	</select>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="flex-1">
												<div className="py-4 border-b border-gray-200 px-4 sm:px-6">
													<div className="flex justify-between">
														<label
															htmlFor="fileName"
															className={
																errors.nextDate
																	? 'block text-sm font-poppins-400 text-red-500'
																	: 'block text-sm font-poppins-400 text-gray-700'
															}
														>
															Next review
														</label>
													</div>
													<div className="relative mt-1">
														<div
															className={
																errors.nextDate
																	? 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3   z-index'
																	: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3   z-index'
															}
														>
															<img src={calenderIcon} alt=""></img>
														</div>
														<Controller
															defaultValue={individualReport?.nextDate}
															control={control}
															render={({ field: { onChange } }) => (
																<DatePicker
																	dateFormat="dd/MM/yyyy"
																	placeholderText={
																		'Select date of next review...'
																	}
																	className={
																		errors.nextDate
																			? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																			: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
																	}
																	onChange={(value: Date) => {
																		setIsFormTouched(true)
																		setStartDate(value)
																		changeSelection('nextDate', value)
																		onChange(value)
																	}}
																	selected={
																		individualReport?.nextDate
																			? new Date(individualReport?.nextDate)
																			: ''
																	}
																	minDate={
																		new Date(
																			new Date().getTime() + 3600000 * 24
																		)
																	}
																/>
															)}
															{...register('nextDate', {
																required: false,
																validate: () => {
																	return (
																		isBefore(new Date(), startDate) &&
																		isValidDate(individualReport?.nextDate)
																	)
																}
															})}
														/>
													</div>
													<p
														className="mt-2 text-xs text-red-500"
														id="email-error"
													>
														<>
															{errors?.nextDate
																? errors?.nextDate.type == 'validate'
																	? 'Please provide future date'
																	: ''
																: ''}
														</>
													</p>
												</div>
											</div>
										</>
									)}

								{/* Raise a Flag Section */}
								{individualReport?.courseProgress !== 'Not Started' && (
									<div className="flex-1">
										<div className="py-4 px-4 sm:px-6">
											<div className="flex justify-between">
												<label
													htmlFor="fileName"
													className="block text-sm font-poppins-400 text-gray-700"
												>
													Raise a Flag
												</label>

												{/* <label className="text-gray-500 font-poppins-400 text-sm">
                          Optional
                        </label> */}
											</div>
											<div className="mt-1 relative">
												<select
													id=" "
													name=" "
													value={individualReport?.furtherAction ?? ''}
													onChange={(e: any) => {
														changeSelection('furtherAction', e.target.value)
													}}
													className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
												>
													<option value="" disabled selected hidden>
														Select
													</option>
													{[
														'None',
														'Attendance Issue',
														'Attitude/Behaviour',
														'Break In Learning',
														'EPA Ready',
														'General Update',
														'High performer',
														'Low performer',
														'Off the Job Concern',
														'Requires Support',
														'Safeguard'
													].map((flag: any) => (
														<option key={flag} value={flag}>
															{flag}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
								)}

								{/* Comment and Optional Section */}
								<div className="py-4 border-b border-t border-gray-200 px-4 sm:px-6">
									<div className="flex justify-between">
										<label
											htmlFor="comment"
											className={
												errors.comment
													? 'block text-sm font-poppins-400 text-red-500'
													: 'block text-sm font-poppins-400 text-gray-700'
											}
										>
											Comment{' '}
											{individualReport?.courseProgress?.toLowerCase() !==
												'not started' &&
											individualReport?.courseProgress &&
											(individualReport?.ragRating?.toLowerCase() === 'amber' ||
												individualReport?.ragRating?.toLowerCase() === 'red')
												? '  *'
												: ''}
										</label>

										{/* <label className="text-gray-500 font-poppins-400 text-sm">
                      Optional
                    </label> */}
									</div>
									<div className="mt-1 relative">
										{_commentRender(individualReport?.comment)}
									</div>
									<p className="mt-1 text-sm text-red-500" id="comment-error">
										<>{errors.comment ? 'Comment is required' : ''}</>
									</p>
								</div>

								{/* File upload Section */}
								{individualReport?.courseProgress !== 'Not Started' && (
									<div className="flex-1">
										<div className="py-4 pb-2 px-4 sm:px-6">
											<div className="flex justify-between">
												<label
													htmlFor="fileName"
													className="block text-sm font-poppins-400 text-gray-700"
												>
													Files ({lstFiles?.length}/5)
												</label>
											</div>
											{lstFiles?.length < 5 && (
												<DropZone
													files={lstFiles}
													onChange={(file: FileObj) => {
														setLoading(true)
														setIsFormTouched(true)
														let items: any = []
														items.push(file)
														// 上传文件
														uploadFile(file)
															.then((key) => {
																setLstFiles((val) => {
																	val = val.concat([
																		{
																			fileurl: key,
																			filetitle: file.name,
																			filetype: file.type,
																			filesize: file.size
																		}
																	])
																	return val
																})
																// setFileKeys([
																//   ...fileKeys,
																//   {
																//     fileKey: key,
																//     fileName: file.name,
																//     fileSize: file.size,
																//   },
																// ]);
															})
															.finally(() => setLoading(false))
													}}
												/>
											)}
										</div>

										{lstFiles.map((i, index) => (
											<div className="px-4 sm:px-6 mb-2" key={index}>
												<div className="flex items-center py-3 pl-3 pr-4 text-sm shadow-sm border border-gray-300 rounded-lg">
													<div
														className="flex w-0 flex-1 items-center cursor-pointer"
														onClick={() => handle4DownloadFile(i.fileurl)}
													>
														<img
															src={attachmentIcon}
															alt="attachment"
															className="flex-shrink-0"
														/>

														<span
															className="ml-2 w-0 flex-1 truncate text-xs font-poppins-500 text-gray-900"
															title={i.name}
														>
															{i.filetitle}
															<span className="font-poppins-400 text-gray-600 inline-flex ml-2">
																{bytesToSize(i.filesize)}
															</span>
														</span>
														<span className="flex-shrink-0 flex justify-end">
															<svg
																onClick={(e) => {
																	e.stopPropagation()
																	const items = lstFiles.filter(
																		(i, ind) => index !== ind
																	)
																	setLstFiles(items)
																	// const keys = fileKeys.filter(
																	//   (i: any, ind: any) => index !== ind
																	// );
																	// setFileKeys(keys);
																	setIsFormTouched(true)
																}}
																width="15"
																height="16"
																className=" text-gray-400"
																viewBox="0 0 15 16"
																fill="currentColor"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path d="M5.29395 5.79395C5.47559 5.60938 5.77441 5.60938 5.95605 5.79395L7.5 7.33789L9.04395 5.79395C9.22559 5.60938 9.52441 5.60938 9.70605 5.79395C9.89062 5.97559 9.89062 6.27441 9.70605 6.45605L8.16211 8L9.70605 9.54395C9.89062 9.72559 9.89062 10.0244 9.70605 10.2061C9.52441 10.3906 9.22559 10.3906 9.04395 10.2061L7.5 8.66211L5.95605 10.2061C5.77441 10.3906 5.47559 10.3906 5.29395 10.2061C5.10938 10.0244 5.10938 9.72559 5.29395 9.54395L6.83789 8L5.29395 6.45605C5.10938 6.27441 5.10938 5.97559 5.29395 5.79395ZM15 8C15 12.1426 11.6426 15.5 7.5 15.5C3.35742 15.5 0 12.1426 0 8C0 3.85742 3.35742 0.5 7.5 0.5C11.6426 0.5 15 3.85742 15 8ZM7.5 1.4375C3.87598 1.4375 0.9375 4.37598 0.9375 8C0.9375 11.624 3.87598 14.5625 7.5 14.5625C11.124 14.5625 14.0625 11.624 14.0625 8C14.0625 4.37598 11.124 1.4375 7.5 1.4375Z" />
															</svg>
														</span>
													</div>
												</div>
											</div>
										))}
									</div>
								)}

								{loading && (
									<div
										className={`${
											loading ? 'h-12 flex items-center justify-center' : ''
										}`}
									>
										<ScreenLoader />
									</div>
								)}
								<div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 mb-0 mt-2">
									<button
										type="button"
										className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
										onClick={() => {
											props.toggle(false)
										}}
									>
										Cancel
									</button>
									<button
										className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
										type="submit"
										onClick={checkForFocus}
									>
										<svg
											className="w-5 h-5 text-white mr-2"
											viewBox="0 0 16 16"
											fill="currentColor"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M7.35313 10.3531C7.15938 10.55 6.84062 10.55 6.64687 10.3531L4.64687 8.35312C4.45 8.15937 4.45 7.84062 4.64687 7.64687C4.84062 7.45 5.15938 7.45 5.35313 7.64687L7 9.29375L10.6469 5.64687C10.8406 5.45 11.1594 5.45 11.3531 5.64687C11.55 5.84062 11.55 6.15938 11.3531 6.35313L7.35313 10.3531ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1Z" />
										</svg>
										Save Changes
									</button>
								</div>
							</form>
						</div>
					</div>
				}
			</AppSlideOver>
		</>
	)
}
