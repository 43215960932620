import { useState, useEffect, useRef } from 'react'
import AppSlideOver from './AppSlideOver'
import { XMarkIcon } from '@heroicons/react/20/solid'
import attachmentIcon from '../assets/Icons/attachmentIcon.svg'
import { DropZone, FileObj } from '../utils/common/FileDrop'
import { IReport } from '../pages/dashboard/reports/IndividualCourse'
import { useForm } from 'react-hook-form'
import axios from '../utils/axios'
import { getFileUrl, uploadFile } from '../utils/fileAction'
import apiUrl from '../utils/apiUrl'

export interface UpdateReport {
	clientid: string
	courseid: string
	learnerid: string
	courseProgress: string
	MathFS: string
	EnglishFS: string
	Rag: string
	Percentage: string
	FunctionalSkills: string
	withdrawn: string
	nextDate: any
	Flag: string
	Comment: string
	Files: number[]
	completionDate: any
	completionStatus: string
	StartDate: any
	PlannedEndDate: any
	EndDate: any
	bilStartDate: any
	bilEndDate: any
	epaStartDate: any
	LeaverDate: any
	AdditionalTag: string
}
export interface IUdate extends IReport {
	completionDate: any
	withdrawn: string
	completionStatus: string
	bilStartDate: any
	bilEndDate: any
	StartDate: any
	PlannedEndDate: any
	EndDate: any
	epaStartDate: any
	LeaverDate: any
	AdditionalTag: string
}

export default function UploadSlide(props: any) {
	const {
		handleSubmit,
		formState: { errors }
	} = useForm()

	const formRef = useRef<any>(null)

	useEffect(() => {
		if (props.selectedData) {
			setLstFiles(
				props.selectedData.file?.fileurl ? [props.selectedData.file] : []
			)
		}
		setLoading(false)
	}, [props.selectedData])

	const [lstFiles, setLstFiles] = useState<any[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [fileUploadError, setFileUploadError] = useState<String>('')
	const bytesToSize = (bytes: number) => {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
		if (bytes === 0) return 'n/a'
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
		if (i === 0) return bytes + ' ' + sizes[i]
		return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
	}

	const submitReport = () => {
		if (lstFiles.length === 0) {
			setFileUploadError('Please select file to upload')
			return
		}
		axios
			.post(apiUrl.reportUploadFile, {
				id: props.selectedData.id,
				...lstFiles[0]
			})
			.then(({ data }) => {
				console.log(data)
				props.toggle(true, true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const checkForFocus = () => {
		if (errors) {
			if (formRef.current !== null) {
				formRef.current.focus()
			}
		}
	}

	const handle4DownloadFile = (key: string) => {
		getFileUrl(key).then((url) => window.open(url))
	}

	return (
		<>
			<AppSlideOver toggle={props.toggle} show={props.show}>
				{
					<div className="flex min-h-0 flex-1 flex-col pt-6 slide-over-input">
						<div className=" px-4 sm:px-6">
							<div className="flex items-start justify-between">
								<div className="text-lg font-inter-500 leading-7 text-gray-900">
									Upload file
								</div>
								<div className="ml-3 flex h-7 items-center">
									<button
										type="button"
										className=" text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => {
											props.toggle(false)
										}}
									>
										<span className="sr-only">Close panel</span>
										<XMarkIcon className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
							</div>
							<div className="sm:px-1 text-sm text-gray-500">
								Upload your report{' '}
							</div>
						</div>

						<div className=" relative overflow-y-auto mt-6 pb-0 flex-1">
							<form
								className="mb-5"
								id="personal_detail"
								onSubmit={handleSubmit(submitReport)}
							>
								<div className="flex-1">
									<div className="py-4 pb-2 px-4 sm:px-6">
										<div className="flex justify-between">
											<label
												htmlFor="fileName"
												className="block text-sm font-poppins-400 text-gray-700"
											>
												Files
											</label>
										</div>
										{lstFiles?.length < 1 && !loading && (
											<DropZone
												files={lstFiles}
												max={1}
												onChange={(file: FileObj) => {
													setLoading(true)
													let items: any = []
													items.push(file)
													// 上传文件
													uploadFile(file)
														.then((key) => {
															setLstFiles((val) => {
																val = val.concat([
																	{
																		fileurl: key,
																		filetitle: file.name,
																		filetype: file.type,
																		filesize: file.size
																	}
																])
																return val
															})
															// setFileKeys([
															//   ...fileKeys,
															//   {
															//     fileKey: key,
															//     fileName: file.name,
															//     fileSize: file.size,
															//   },
															// ]);
														})
														.finally(() => setLoading(false))
												}}
											/>
										)}
									</div>
									{fileUploadError && (
										<span className="font-poppins-400 text-red-800 text-sm">
											{fileUploadError}
										</span>
									)}

									{lstFiles.map((i, index) => (
										<div className="px-4 sm:px-6 mb-2" key={index}>
											<div className="flex items-center py-3 pl-3 pr-4 text-sm shadow-sm border border-gray-300 rounded-lg">
												<div
													className="flex w-0 flex-1 items-center cursor-pointer"
													onClick={() => handle4DownloadFile(i.fileurl)}
												>
													<img
														src={attachmentIcon}
														alt="attachment"
														className="flex-shrink-0"
													/>

													<span
														className="ml-2 w-0 flex-1 truncate text-xs font-poppins-500 text-gray-900"
														title={i.name}
													>
														{i.filetitle}
														<span className="font-poppins-400 text-gray-600 inline-flex ml-2">
															{bytesToSize(i.filesize)}
														</span>
													</span>
													<span className="flex-shrink-0 flex justify-end">
														<svg
															onClick={(e) => {
																e.stopPropagation()
																const items = lstFiles.filter(
																	(i, ind) => index !== ind
																)
																setLstFiles(items)
																// const keys = fileKeys.filter(
																//   (i: any, ind: any) => index !== ind
																// );
																// setFileKeys(keys);
															}}
															width="15"
															height="16"
															className=" text-gray-400"
															viewBox="0 0 15 16"
															fill="currentColor"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path d="M5.29395 5.79395C5.47559 5.60938 5.77441 5.60938 5.95605 5.79395L7.5 7.33789L9.04395 5.79395C9.22559 5.60938 9.52441 5.60938 9.70605 5.79395C9.89062 5.97559 9.89062 6.27441 9.70605 6.45605L8.16211 8L9.70605 9.54395C9.89062 9.72559 9.89062 10.0244 9.70605 10.2061C9.52441 10.3906 9.22559 10.3906 9.04395 10.2061L7.5 8.66211L5.95605 10.2061C5.77441 10.3906 5.47559 10.3906 5.29395 10.2061C5.10938 10.0244 5.10938 9.72559 5.29395 9.54395L6.83789 8L5.29395 6.45605C5.10938 6.27441 5.10938 5.97559 5.29395 5.79395ZM15 8C15 12.1426 11.6426 15.5 7.5 15.5C3.35742 15.5 0 12.1426 0 8C0 3.85742 3.35742 0.5 7.5 0.5C11.6426 0.5 15 3.85742 15 8ZM7.5 1.4375C3.87598 1.4375 0.9375 4.37598 0.9375 8C0.9375 11.624 3.87598 14.5625 7.5 14.5625C11.124 14.5625 14.0625 11.624 14.0625 8C14.0625 4.37598 11.124 1.4375 7.5 1.4375Z" />
														</svg>
													</span>
												</div>
											</div>
										</div>
									))}
								</div>

								{loading && (
									<div
										className={`${
											loading
												? 'mx-3 justify-center flex items-center py-3 pl-3 pr-4 text-sm shadow-sm border border-gray-300 rounded-lg'
												: ''
										}`}
									>
										<div className="flex items-center justify-center mx-auto">
											<div className="half-ring-2 mr-3"></div>
											Uploading
										</div>
									</div>
								)}
								<div className=" flex flex-shrink-0 w-full justify-end px-4 border-t py-3 mb-0 mt-2 fixed bottom-0">
									<button
										type="button"
										className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
										onClick={() => {
											props.toggle(false)
										}}
									>
										Cancel
									</button>
									<button
										className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
										type="submit"
										onClick={checkForFocus}
									>
										<svg
											className="w-5 h-5 text-white mr-2"
											viewBox="0 0 16 16"
											fill="currentColor"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M7.35313 10.3531C7.15938 10.55 6.84062 10.55 6.64687 10.3531L4.64687 8.35312C4.45 8.15937 4.45 7.84062 4.64687 7.64687C4.84062 7.45 5.15938 7.45 5.35313 7.64687L7 9.29375L10.6469 5.64687C10.8406 5.45 11.1594 5.45 11.3531 5.64687C11.55 5.84062 11.55 6.15938 11.3531 6.35313L7.35313 10.3531ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1Z" />
										</svg>
										Save
									</button>
								</div>
							</form>
						</div>
					</div>
				}
			</AppSlideOver>
		</>
	)
}
